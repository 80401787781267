import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { createI18n } from 'vue-i18n'
import routes from '@/routes'
import * as Sentry from '@sentry/vue'

import '@/style.css'
import App from '@/App.vue'
import translations from './translations'

const i18n = createI18n(translations)
const pinia = createPinia()
const app = createApp(App)

Sentry.init({
  app,
  dsn: 'https://f01da6d2813ed02c719e0b85dad9cd46@o4507723648139264.ingest.de.sentry.io/4507723685625936',
  integrations: [
    //Sentry.browserTracingIntegration(),
    //Sentry.replayIntegration(),
  ]
  // Performance Monitoring
  //tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  //tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  //replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  //replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

app.use(i18n)
app.use(pinia)
app.use(routes)
app.mount('#app')
