<template>
  <button
    class="inline-flex items-center gap-3 rounded-[34px] px-10 py-4 text-lg font-medium disabled:cursor-not-allowed disabled:border disabled:border-gray-400 disabled:bg-gray-50 disabled:text-gray-400 disabled:hover:opacity-80"
    :class="variantClasses"
  >
    <slot />
  </button>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

interface Props {
  variant?: 'default' | 'outlined' | 'text'
}
const { variant = 'default' } = defineProps<Props>()

const variantMap: Record<Required<string>, string> = {
  default: 'text-white bg-accent-500 hover:opacity-80 ',
  outlined: 'text-accent-500 bg-accent-500/10 hover:opacity-80 border border-accent-500',
  text: 'text-accent-500'
}

const variantClasses = computed(() => variantMap[variant] || variantMap.default)
</script>
