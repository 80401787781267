import de from './de'
import en from './en'

export default {
  legacy: false, // you must set `false`, to use Composition API
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en,
    de
  }
}
