import { computed, ref } from 'vue'
import useWeakCookies from '@/composables/useWeakCookies'
import { useStorage } from '@vueuse/core'
import { useI18n } from 'vue-i18n'

const TIP_ON_CONSULTATION_START = 'TIP_ON_CONSULTATION_START'
const TIP_RECOMMENDED_BROWSER = 'TIP_RECOMMENDED_BROWSER'

const useDeviceAndBrowser = () => {
  const userAgent = navigator.userAgent
  const isAndroid = /android/i.test(userAgent)
  const isIOS = /iphone|ipad|ipod|crios/i.test(userAgent)
  const isWindows = /windows/i.test(userAgent)
  const isMac = /macintosh/i.test(userAgent)
  const isLinux = /linux/i.test(userAgent)

  const isChrome = /chrome|crios/i.test(userAgent) && !/edge|edg/.test(userAgent)
  const isFirefox = /firefox|fennec|focus|fxios/i.test(userAgent)
  const isSafari = /safari/i.test(userAgent) && !/chrome/.test(userAgent)
  const isEdge = /edg/i.test(userAgent)
  const isOpera = /opera|opr/i.test(userAgent)
  const isMobile =
    /Mobi|Mobile|Tablet|iPhone|iPad|Android|IEMobile|Opera Mini/i.test(userAgent) ||
    isAndroid ||
    isIOS

  const { t } = useI18n()

  const _popupMessages: Record<string, { title: string; text: string[]; button: string }> = {
    androidNotChrome: {
      title: t('dialogs.deviceAndBrowserTips.androidNotChrome.title'),
      text: [
        t('dialogs.deviceAndBrowserTips.androidNotChrome.content1'),
        t('dialogs.deviceAndBrowserTips.androidNotChrome.content2'),
        t('dialogs.deviceAndBrowserTips.androidNotChrome.content3')
      ],
      button: t('dialogs.deviceAndBrowserTips.androidNotChrome.actions.ok')
    },
    androidFirefox: {
      title: t('dialogs.deviceAndBrowserTips.androidFirefox.title'),
      text: [t('dialogs.deviceAndBrowserTips.androidFirefox.content1')],
      button: t('dialogs.deviceAndBrowserTips.androidFirefox.actions.ok')
    },
    androidChrome: {
      title: t('dialogs.deviceAndBrowserTips.androidChrome.title'),
      text: [
        t('dialogs.deviceAndBrowserTips.androidChrome.content1'),
        t('dialogs.deviceAndBrowserTips.androidChrome.content2')
      ],
      button: t('dialogs.deviceAndBrowserTips.androidChrome.actions.ok')
    },
    ios: {
      title: t('dialogs.deviceAndBrowserTips.ios.title'),
      text: [t('dialogs.deviceAndBrowserTips.ios.content1')],
      button: t('dialogs.deviceAndBrowserTips.ios.actions.ok')
    }
  }

  // For warning on consultation recording
  const getPopupMessage = () => {
    switch (true) {
      case isAndroid && !isChrome && !isFirefox:
        return _popupMessages.androidNotChrome
      case isAndroid && isFirefox:
        return _popupMessages.androidFirefox
      case isAndroid && isChrome:
        return _popupMessages.androidChrome
      case isIOS:
        return _popupMessages.ios
      default:
        return null
    }
  }

  const browserRecommendationPopupVisible = ref(false)
  const consultationStartPopupVisible = ref(false)
  const consultationStartPopupMessageData = computed(getPopupMessage)

  const consultationStartTip = useStorage(TIP_ON_CONSULTATION_START, false)
  const browserRecommendationTip = useStorage(TIP_ON_CONSULTATION_START, false)

  const { getCookie, setCookie } = useWeakCookies()

  const checkDeviceAndBrowserAndShowTip = () => {
    const cookie = getCookie(TIP_ON_CONSULTATION_START) || consultationStartTip.value

    if (consultationStartPopupMessageData.value && !cookie) {
      consultationStartPopupVisible.value = true
      return true
    }

    return false
  }

  const hideConsultationStartPopup = () => {
    const cookie = getCookie(TIP_ON_CONSULTATION_START) || consultationStartTip.value

    if (!cookie) {
      setCookie(TIP_ON_CONSULTATION_START, 'shown')
      consultationStartTip.value = true
    }

    consultationStartPopupVisible.value = false
  }

  const showBrowserRecommendationPopup = () => {
    const cookie = getCookie(TIP_RECOMMENDED_BROWSER) || browserRecommendationTip.value

    if (isAndroid && !isChrome && !cookie) {
      browserRecommendationPopupVisible.value = true
    }
  }

  const hideBrowserRecommendationPopup = () => {
    const cookie = getCookie(TIP_RECOMMENDED_BROWSER) || browserRecommendationTip.value

    if (!cookie) {
      setCookie(TIP_RECOMMENDED_BROWSER, 'shown')
      browserRecommendationTip.value = true
    }

    browserRecommendationPopupVisible.value = false
  }

  return {
    getPopupMessage,
    consultationStartPopupMessageData,
    consultationStartPopupVisible,
    checkDeviceAndBrowserAndShowTip,
    hideConsultationStartPopup,
    browserRecommendationPopupVisible,
    hideBrowserRecommendationPopup,
    showBrowserRecommendationPopup,
    userAgent,
    isAndroid,
    isIOS,
    isWindows,
    isMac,
    isLinux,
    isChrome,
    isFirefox,
    isSafari,
    isEdge,
    isOpera,
    isMobile
  }
}

export default useDeviceAndBrowser
