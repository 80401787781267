<template>
  <div class="group relative">
    <div
      role="tooltip"
      class="tooltip hidden text-center text-base text-white"
      :class="{
        '!visible !block !opacity-80': visibleControl,
        'group-hover:!visible group-hover:!block group-hover:!opacity-80': props.isOnHover,
        'tooltip--top': props.placement === 'top',
        'tooltip--left': props.placement === 'left',
        'tooltip--bottom': props.placement === 'bottom',
        'tooltip--right': props.placement === 'right'
      }"
    >
      {{ tooltip }}
    </div>

    <slot />
  </div>
</template>

<script lang="ts" setup>
import { useVModel } from '@vueuse/core'
import { watchEffect } from 'vue'

interface Props {
  tooltip: string
  modelValue?: boolean
  duration?: number
  isOnHover?: boolean
  placement?: 'top' | 'bottom' | 'left' | 'right'
}
const props = withDefaults(defineProps<Props>(), {
  duration: 3000,
  modelValue: false,
  isOnHover: false,
  placement: 'top'
})
const emit = defineEmits<{
  'update:modelValue': [boolean]
}>()

const visibleControl = useVModel(props, 'modelValue', emit, { passive: true })

watchEffect(() => {
  let timerId = null

  if (visibleControl.value) {
    timerId = setTimeout(() => (visibleControl.value = false), props.duration)
  }

  return () => {
    if (timerId) {
      clearTimeout(timerId)
    }
  }
})
</script>
