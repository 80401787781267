export default {
  welcome: {
    greeting: 'Welcome to',
    message:
      'AI-Documentation software for Healthcare Providers. Save Time, Enhance Care & Compliance.',
    reset: {
      title1: 'Reset',
      title2: 'Password'
    }
  },
  formControls: {
    email: {
      label: 'Email',
      placeholder: 'Enter your email'
    },
    password: {
      label: 'Password',
      placeholder: 'Enter your password'
    }
  },
  signInActions: {
    login: 'Login',
    lostPassword: 'lskdmfsd Lost your password?',
    continue: 'Continue',
    goToLogin: 'Go to Login',
    tips: {
      emailSent: 'We just sent you an email with a reset link',
      checkEmail: 'If your email is registered, you will receive a password reset link shortly.'
    }
  },
  formValidationErrorMessages: {
    usernamePassword: 'Please fill you username and password.',
    username: 'Please fill you username.',
    email: 'Please fill you email.',
    password: 'Please fill you password.'
  },
  wakeLock: {
    notSupported: 'Wake Lock is not supported by the current browser.'
  }
}
