<template>
  <a :href="userData?.user ? '#' : 'https://www.playnvoice.ai'" class="relative inline-block">
    <svg class="h-9" viewBox="0 0 277 61" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M74.1094 28.917C73.3406 30.3306 72.1591 31.4803 70.5276 32.3285C68.8961 33.1955 66.852 33.629 64.3954 33.629H59.3508V45.2205H53.3124V15.0072H64.3766C66.702 15.0072 68.6898 15.403 70.3213 16.2135C71.9528 17.0239 73.1905 18.136 73.9969 19.5496C74.822 20.9632 75.2159 22.5652 75.2159 24.3558C75.2159 25.9767 74.8408 27.4846 74.0719 28.8982L74.1094 28.917ZM67.8459 27.5976C68.6523 26.8249 69.0461 25.7505 69.0461 24.3746C69.0461 21.4344 67.4146 19.9642 64.1328 19.9642H59.3508V28.7474H64.1328C65.8019 28.7474 67.0396 28.3704 67.8459 27.5976Z"
        fill="#161616"
      />
      <path d="M83.8202 13.1978V45.2204H77.7817V13.1978H83.8202Z" fill="#161616" />
      <path
        d="M88.7944 26.7118C89.7508 24.827 91.0635 23.3945 92.7138 22.3767C94.364 21.3589 96.2018 20.8689 98.2459 20.8689C100.027 20.8689 101.584 21.227 102.915 21.9432C104.247 22.6595 105.316 23.583 106.122 24.6762V21.2647H112.198V45.2393H106.122V41.7336C105.353 42.8645 104.284 43.788 102.915 44.5231C101.546 45.2582 99.9712 45.6351 98.2084 45.6351C96.2018 45.6351 94.364 45.1074 92.7138 44.0707C91.0635 43.0341 89.7508 41.564 88.7944 39.6792C87.838 37.7944 87.3504 35.608 87.3504 33.1578C87.3504 30.7075 87.838 28.5777 88.7944 26.7118ZM105.26 29.4636C104.678 28.4081 103.909 27.5976 102.934 27.0322C101.959 26.4667 100.909 26.184 99.7836 26.184C98.6585 26.184 97.6271 26.4667 96.6894 27.0133C95.733 27.5599 94.9641 28.3704 94.3828 29.407C93.8014 30.4625 93.5014 31.7065 93.5014 33.1578C93.5014 34.6091 93.8014 35.8719 94.3828 36.9462C94.9641 38.0205 95.7518 38.8499 96.7082 39.4341C97.6646 40.0184 98.696 40.3011 99.7836 40.3011C100.871 40.3011 101.959 40.0184 102.934 39.453C103.909 38.8876 104.678 38.0771 105.26 37.0216C105.841 35.9661 106.122 34.7033 106.122 33.2332C106.122 31.763 105.841 30.5002 105.26 29.4447V29.4636Z"
        fill="#161616"
      />
      <path
        d="M139.201 21.2457L124.424 56.5668H118.01L123.186 44.6172L113.622 21.2457H120.392L126.543 38.0016L132.788 21.2457H139.201Z"
        fill="#161616"
      />
      <path
        d="M161.69 23.6018C163.434 25.4112 164.315 27.918 164.315 31.1598V45.2204H158.277V31.9703C158.277 30.0667 157.808 28.5965 156.852 27.5787C155.895 26.5609 154.62 26.0332 152.97 26.0332C151.32 26.0332 149.988 26.5421 149.032 27.5787C148.075 28.5965 147.588 30.0667 147.588 31.9703V45.2204H141.549V21.2458H147.588V24.2238C148.394 23.1871 149.426 22.3767 150.663 21.7736C151.92 21.1893 153.289 20.8877 154.77 20.8877C157.621 20.8877 159.908 21.7924 161.652 23.6018H161.69Z"
        fill="#161616"
      />
      <path
        d="M192.739 15.0074L181.713 45.2207H174.399L163.372 15.0074H169.823L178.093 39.0386L186.326 15.0074H192.739Z"
        fill="#161616"
      />
      <path
        d="M196.437 44.0896C194.6 43.0718 193.156 41.6205 192.106 39.7357C191.055 37.8509 190.53 35.7022 190.53 33.252C190.53 30.8018 191.074 28.6343 192.143 26.7683C193.212 24.8835 194.693 23.4511 196.55 22.4144C198.425 21.3966 200.488 20.8689 202.795 20.8689C205.101 20.8689 207.164 21.3778 209.039 22.4144C210.915 23.4322 212.378 24.8835 213.446 26.7683C214.515 28.6531 215.059 30.8018 215.059 33.252C215.059 35.7022 214.515 37.8698 213.409 39.7357C212.303 41.6205 210.802 43.0529 208.927 44.0896C207.052 45.1074 204.951 45.6351 202.626 45.6351C200.301 45.6351 198.257 45.1262 196.419 44.0896H196.437ZM205.72 39.5472C206.677 39.0195 207.445 38.209 208.027 37.1535C208.608 36.0792 208.889 34.7787 208.889 33.252C208.889 30.9714 208.289 29.2185 207.108 27.9934C205.908 26.7683 204.464 26.1463 202.738 26.1463C201.013 26.1463 199.569 26.7683 198.407 27.9934C197.244 29.2185 196.663 30.9714 196.663 33.252C196.663 35.5326 197.225 37.2855 198.369 38.5106C199.494 39.7357 200.938 40.3577 202.663 40.3577C203.751 40.3577 204.783 40.0938 205.739 39.5661L205.72 39.5472Z"
        fill="#161616"
      />
      <path
        d="M219.071 17.3819C218.358 16.7033 218.021 15.8552 218.021 14.8562C218.021 13.8573 218.377 13.0091 219.071 12.3306C219.765 11.6521 220.665 11.3128 221.715 11.3128C222.765 11.3128 223.665 11.6521 224.359 12.3306C225.053 13.0091 225.409 13.8573 225.409 14.8562C225.409 15.8552 225.053 16.7033 224.359 17.3819C223.647 18.0604 222.765 18.3996 221.715 18.3996C220.665 18.3996 219.765 18.0604 219.071 17.3819ZM224.697 21.2645V45.2391H218.658V21.2457H224.697V21.2645Z"
        fill="#161616"
      />
      <path
        d="M229.723 26.7307C230.735 24.8648 232.123 23.4323 233.905 22.3957C235.686 21.3779 237.73 20.8502 240.018 20.8502C242.981 20.8502 245.419 21.5852 247.369 23.0742C249.301 24.5632 250.614 26.6365 251.27 29.3317H244.763C244.425 28.2951 243.844 27.4846 243.019 26.8815C242.194 26.2972 241.181 25.9957 239.981 25.9957C238.255 25.9957 236.886 26.6176 235.893 27.8804C234.88 29.1433 234.392 30.915 234.392 33.2333C234.392 35.5516 234.899 37.2856 235.893 38.5295C236.905 39.7924 238.255 40.4143 239.981 40.4143C242.419 40.4143 244.013 39.3212 244.763 37.1159H251.27C250.614 39.717 249.301 41.7714 247.351 43.2981C245.4 44.8248 242.962 45.5975 240.037 45.5975C237.73 45.5975 235.705 45.0886 233.923 44.052C232.142 43.0342 230.754 41.5829 229.742 39.717C228.729 37.851 228.241 35.6835 228.241 33.1956C228.241 30.7076 228.748 28.5401 229.742 26.6742L229.723 26.7307Z"
        fill="#161616"
      />
      <path
        d="M276.85 35.0615H259.41C259.56 36.7955 260.16 38.1526 261.21 39.1327C262.279 40.1128 263.573 40.6028 265.129 40.6028C267.361 40.6028 268.955 39.6416 269.911 37.7002H276.419C275.725 39.9997 274.412 41.9033 272.462 43.3923C270.511 44.8813 268.111 45.6164 265.261 45.6164C262.954 45.6164 260.91 45.1075 259.072 44.0708C257.253 43.0531 255.828 41.6018 254.815 39.717C253.803 37.8322 253.277 35.6835 253.277 33.2333C253.277 30.783 253.784 28.5778 254.778 26.693C255.79 24.8082 257.197 23.3758 258.997 22.358C260.797 21.3402 262.879 20.8502 265.242 20.8502C267.605 20.8502 269.536 21.3402 271.337 22.3203C273.137 23.3004 274.525 24.6951 275.518 26.5045C276.512 28.314 277 30.3872 277 32.7244C277 33.5914 276.944 34.3641 276.831 35.0615H276.85ZM270.774 30.9904C270.736 29.426 270.193 28.182 269.086 27.2396C267.999 26.2972 266.667 25.826 265.073 25.826C263.573 25.826 262.316 26.2784 261.304 27.1831C260.291 28.0878 259.653 29.3506 259.428 30.9715H270.755L270.774 30.9904Z"
        fill="#161616"
      />
      <path
        d="M0 23.3565C0 20.6047 0.806378 17.9283 2.30662 15.6289C3.80686 13.3483 5.96345 11.5577 8.47635 10.4834L32.3301 0.343186C32.9865 0.0604666 33.6991 -0.0526212 34.4117 0.0227706C35.1243 0.0981625 35.7994 0.324338 36.3995 0.720145C36.9996 1.11595 37.4872 1.66254 37.8248 2.28453C38.1623 2.92536 38.3311 3.62273 38.3311 4.33895V18.4749C38.3311 20.4917 37.731 22.4707 36.6246 24.1482C35.5182 25.8256 33.9429 27.145 32.1051 27.9366L7.59496 38.3784C4.20067 39.8108 1.48149 42.4872 0 45.8799V23.3565Z"
        fill="#161616"
      />
      <path
        d="M22.7437 39.8486V56.661C22.7437 57.3018 22.5749 57.9238 22.2561 58.4704C21.9373 59.017 21.4872 59.4882 20.9246 59.8275C20.3621 60.1667 19.7432 60.3364 19.0869 60.3741C18.4305 60.3929 17.7929 60.261 17.2116 59.9594L9.24154 55.9259C8.22888 55.4171 7.36624 54.6254 6.80365 53.6642C6.22231 52.7029 5.94101 51.5909 5.99727 50.46C5.99727 49.3669 6.35358 48.3114 6.97243 47.4067C7.59128 46.502 8.47267 45.7857 9.48533 45.3522L22.7624 39.8486H22.7437Z"
        fill="#161616"
      />
    </svg>
  </a>
</template>

<script setup lang="ts">
import { useUserDataStore } from '@/stores/user'

const { userData } = useUserDataStore()
</script>
