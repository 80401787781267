<template>
  <Menu as="div" class="relative inline-block text-left">
    <div>
      <MenuButton
        class="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white p-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
      >
        <div class="flex grow items-center gap-3 overflow-hidden">
          <div
            v-if="userData?.user?.avatar"
            class="h-10 w-10 shrink-0 overflow-hidden rounded-full border border-accent-500"
          >
            <img
              class="block h-full w-full object-cover object-center"
              :src="userData?.user?.avatar"
              alt="avatar"
            />
          </div>

          <span class="truncate text-base font-semibold">{{ userData?.user?.full_name }}</span>
        </div>

        <ChevronDownIcon class="-mr-1 h-5 w-5 shrink-0 text-gray-400" aria-hidden="true" />
      </MenuButton>
    </div>

    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems
        class="absolute right-0 z-10 mt-2 w-56 origin-top-right overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none lg:bottom-[calc(100%+2px)] lg:left-0"
      >
        <div class="divide-y divide-gray-100">
          <MenuItem>
            <div class="font-base truncate px-4 py-4 font-medium">
              {{ userData?.user?.username }}
            </div>
          </MenuItem>

          <MenuItem v-if="version">
            <div class="font-base truncate px-4 py-4 text-center font-medium">
              {{ version }} / {{ userData?.user?.lang }}
            </div>
          </MenuItem>

          <MenuItem>
            <button
              type="button"
              class="block w-full bg-accent-500/10 px-3 py-3 text-center text-black hover:bg-accent-500 hover:text-white"
              @click="handleLogout"
            >
              {{ $t('signInActions.logout') }}
            </button>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script lang="ts" setup>
import { useRouter } from 'vue-router'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/20/solid'
import { useUserDataStore } from '@/stores/user'
import { useConsultationsStore } from '@/stores/consultations'

const version = import.meta.env?.VITE_APP_VERSION || ''
const { userData, clearUserData } = useUserDataStore()
const { clearConsultationList } = useConsultationsStore()

const router = useRouter()

const handleLogout = () => {
  clearUserData()
  clearConsultationList()

  router.push({ name: 'login' })
}
</script>
