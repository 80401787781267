<template>
  <BaseDialog
    :title="$t('dialogs.reloadPage.title')"
    :content="$t('dialogs.reloadPage.content')"
    type="success"
    :visible="isVisible"
    disable-overlay
  >
    <template #actions>
      <button
        type="button"
        class="flex items-center justify-center gap-3 rounded-md border border-accent-500 bg-accent-500/10 px-4 py-2 text-xl font-semibold text-accent-500 shadow-sm hover:bg-accent-500/30 sm:ml-3 sm:w-auto xl:text-base"
        @click="refreshApp"
      >
        {{ $t('dialogs.reloadPage.actions.reload') }}
      </button>
    </template>
  </BaseDialog>
</template>

<script setup lang="ts">
// @ts-ignore
import { useRegisterSW } from 'virtual:pwa-register/vue'
import { useUpdateNotification } from '@/composables/useUpdateNotification.ts'
import BaseDialog from '@/components/base/BaseDialog.vue'
import { useDocumentVisibility, watchDebounced } from '@vueuse/core'
import { ref, watch } from 'vue'
import { useConsultationsStore } from '@/stores/consultations'
import { useRecordStore } from '@/stores/record'
import { useAppStore } from '@/stores/app'

const { refreshApp } = useUpdateNotification()
const appStore = useAppStore()
const consultationsStore = useConsultationsStore()
const docVisibility = useDocumentVisibility()
const recordStore = useRecordStore()

const isVisible = ref(false)

watchDebounced(
  [
    () => appStore.updateAvailable,
    () => docVisibility.value,
    () => consultationsStore.consolidatingConsultation,
    () => recordStore.isRecording
  ],
  ([newUpdateAvailable, newDocVisible, newConsolidating, newIsRecording]) => {
    isVisible.value =
      newUpdateAvailable && newDocVisible === 'visible' && !newConsolidating && !newIsRecording

    console.log('[RELOAD_PROMPT] => watchDebounced => isVisible', isVisible.value)
  },
  { debounce: 300, maxWait: 500 }
)

watch(docVisibility, (newDocVisibility) => {
  if (
    newDocVisibility === 'visible' &&
    !consultationsStore.consolidatingConsultation &&
    !recordStore.isRecording
  ) {
    console.log(
      '[RELOAD_PROMPT] => watch => newDocVisibility && !consultationsStore.consolidatingConsultation && !recordStore.isRecording'
    )
    appStore.immediateCheckForUpdates()
  }
})
</script>
