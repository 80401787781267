import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useRecordStore = defineStore('record', () => {
  const isRecording = ref(false)

  return {
    isRecording
  }
})
