import { IUserData } from '@/types'
import { defineStore } from 'pinia'
import { computed, reactive } from 'vue'
import { useI18n } from 'vue-i18n'

export const useUserDataStore = defineStore('userData', () => {
  const userData = reactive<IUserData>({
    access_token: null,
    token_type: null,
    user: null
  })
  const { availableLocales } = useI18n({ useScope: 'global' })

  const isLoggedIn = computed(
    () => !!(userData.user && userData.access_token && userData.token_type)
  )

  function setUserData(newUserData: IUserData) {
    localStorage.setItem('userData', JSON.stringify(newUserData))

    userData.access_token = newUserData.access_token
    userData.token_type = newUserData.token_type
    userData.user = newUserData.user
  }

  function clearUserData() {
    userData.access_token = null
    userData.token_type = null
    userData.user = null

    localStorage.removeItem('userData')
  }

  function setUserDataFromLocalStorage() {
    const uDate = localStorage.getItem('userData')

    if (!uDate) {
      return
    }

    setUserData(JSON.parse(uDate))
  }

  function changeLocale(newLocale?: string) {
    if (newLocale && availableLocales.includes(newLocale) && userData.user) {
      setUserData({
        ...userData,
        user: {
          ...userData.user,
          lang: newLocale
        }
      })
    }
  }

  return {
    userData,
    isLoggedIn,
    setUserData,
    clearUserData,
    setUserDataFromLocalStorage,
    changeLocale
  }
})
