import { onMounted } from 'vue'
import { useRegisterSW } from 'virtual:pwa-register/vue'
import { useAppStore } from '@/stores/app'

export function useUpdateNotification() {
  console.log('!!!!!!! useUpdateNotification fired')
  const appStore = useAppStore()

  const intervalMS = import.meta.env?.VITE_CHECK_FRESH_VERSION_INTERVAL || 60 * 60 * 1000

  onMounted(appStore.initSWRegistration)

  const { offlineReady, needRefresh, updateServiceWorker } = useRegisterSW({
    onRegisteredSW(swUrl, r) {
      if (r) {
        console.log('!!!! onRegisteredSW fired')
        appStore.registration = r
        setInterval(async () => {
          if (r.installing || !navigator) return

          if ('connection' in navigator && !navigator.onLine) return

          const resp = await fetch(swUrl, {
            cache: 'no-store',
            headers: {
              cache: 'no-store',
              'cache-control': 'no-cache'
            }
          })

          if (resp?.status === 200) {
            console.log('!!!!! onRegisteredSW: await r.update() fired')
            await r.update()
          }
        }, intervalMS)
      }
    },
    onNeedRefresh() {
      console.log('!!! onNeedRefresh ')
      if (import.meta.env?.PROD) {
        // Check only for Production
        appStore.updateAvailable = true
      }
    }
  })

  const refreshApp = () => {
    updateServiceWorker(true)
    appStore.updateAvailable = false
  }

  const cancel = () => {
    appStore.updateAvailable = false
    offlineReady.value = false
    needRefresh.value = false
  }

  return {
    offlineReady,
    needRefresh,
    cancel,
    refreshApp
  }
}
