import errorLoggerService from '@/services/errorLoggerService'

export function throwError(message: string, location: string, response: any, options?: object) {
  const userData = JSON.parse(localStorage.getItem('userData') || 'null')
  const username = userData?.user?.username || 'Error with user name'

  throw { message, options: { username, response, location, ...options } }
}

export async function logError(
  message: string,
  location: string,
  response: any,
  options: object = {}
) {
  if (!import.meta.env?.PROD) {
    console.log(`The error-logger doesn't send logs to the server if it's not production mode`)
    return
  }

  const userData = JSON.parse(localStorage.getItem('userData') || 'null')
  const username = userData?.user?.username || 'Error with user name'

  try {
    await errorLoggerService.notifyError(message, { username, response, location, ...options })
  } catch (error: unknown) {
    console.log(error)
  }
}
