<template>
  <slot name="activator" :toggleVisibility="toggleVisibility" :visible="visibilityControl" />

  <TransitionRoot as="template" :show="visibilityControl">
    <Dialog class="relative z-[10000]" @close="handleClose">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-[10000] w-screen overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6"
            >
              <div class="gap-4 sm:flex sm:items-start">
                <div
                  v-show="$slots.icon"
                  class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10"
                  :class="colorClasses.icon"
                >
                  <slot name="icon" />
                </div>

                <div class="mt-3 w-full text-center sm:mt-0 sm:text-left">
                  <DialogTitle as="h3" class="text-2xl font-semibold leading-6 text-gray-900">
                    <slot name="title" />
                    <template v-if="!$slots.title">
                      {{ props.title }}
                    </template>
                  </DialogTitle>

                  <div class="mt-2 text-xl text-gray-500 lg:text-lg">
                    <slot />

                    <p v-if="!$slots.default">
                      {{ content }}
                    </p>
                  </div>
                </div>
              </div>

              <div
                class="mt-5 flex flex-col gap-y-3 sm:mt-4 sm:flex-row-reverse [&>button]:outline-none"
              >
                <slot name="actions" :onOk="handleOk" :onCancel="handleCancel" />

                <template v-if="!$slots.actions">
                  <button
                    type="button"
                    class="flex items-center justify-center gap-3 rounded-md border px-4 py-2 text-xl font-semibold shadow-sm disabled:!cursor-not-allowed disabled:!border disabled:!border-gray-400 disabled:!bg-gray-50 disabled:!text-gray-400 disabled:hover:!opacity-80 sm:ml-3 sm:w-auto xl:text-base"
                    :disabled="okDisabled || loading"
                    :class="colorClasses.okButton"
                    @click="handleOk"
                  >
                    {{ computedOkText }}
                  </button>

                  <button
                    v-if="props.cancelText !== null"
                    type="button"
                    class="flex items-center justify-center gap-3 rounded-md border border-gray-500 bg-white/20 px-4 py-2 text-xl font-semibold text-gray-500 hover:bg-gray-500/30 xl:text-base"
                    @click="handleCancel"
                  >
                    {{ computedCancelText }}
                  </button>
                </template>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script lang="ts" setup>
import { useVModel } from '@vueuse/core'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'

interface Props {
  visible?: boolean
  title?: string
  content?: string
  onPressOk?: Function
  onPressCancel?: Function
  okText?: string
  okProps?: any
  cancelText?: string | null
  loading?: boolean
  okDisabled?: boolean
  type: 'success' | 'warn' | 'info' | 'error'
  disableOverlay?: boolean
}

const { t } = useI18n()

const props = withDefaults(defineProps<Props>(), {
  type: 'info',
  loading: false,
  okDisabled: false,
  disableOverlay: false
})
const emit = defineEmits<{
  'update:visible': [boolean]
  cancel: []
  ok: []
}>()

const visibilityControl = useVModel(props, 'visible', emit, { passive: true, defaultValue: false })
const typeColorClassMap: Record<Props['type'], any> = {
  success: {
    icon: 'bg-green-100 text-green-500',
    okButton: 'text-accent-500 border-accent-500 bg-accent-500/10 hover:bg-accent-500/30'
  },
  info: {
    icon: 'bg-blue-100 text-blue-500',
    okButton: 'text-blue-500 border-blue-500 bg-blue-500/10 hover:bg-blue-500/30'
  },
  warn: {
    icon: 'bg-yellow-100 text-yellow-500',
    okButton: 'text-yellow-500 border-yellow-500 bg-yellow-500/10 hover:bg-yellow-500/30'
  },
  error: {
    icon: 'bg-red-100 text-red-500',
    okButton: 'text-red-500 border-red-500 bg-red-500/10 hover:bg-red-500/30'
  }
}
const computedOkText = computed(() => props.okText || t('dialogs.default.actions.ok'))
const computedCancelText = computed(() => props.cancelText || t('dialogs.default.actions.cancel'))

const colorClasses = computed(() => typeColorClassMap[props.type])

function handleCancel() {
  if (!props.onPressCancel) {
    toggleVisibility()
    return emit('cancel')
  }

  props.onPressCancel()
}

function handleOk() {
  if (!props.onPressOk) {
    toggleVisibility()
    return emit('ok')
  }

  props.onPressOk()
}

function toggleVisibility() {
  visibilityControl.value = !visibilityControl.value
}

function handleClose() {
  if (!props.disableOverlay) {
    toggleVisibility()
  }
}
</script>
