import axios, { AxiosError } from 'axios'
import { toaster } from '@/utils/toaster'
import router from '@/routes'
import { useUserDataStore } from '@/stores/user'
import { useConsultationsStore } from '@/stores/consultations'

/* ts-ignore */
const headers: Record<string, string> = {
  'Access-Control-Allow-Origin': '*',
  'ngrok-skip-browser-warning': '1' // otherwise ngrok blocks the requests! https://ngrok.com/docs/errors/err_ngrok_6024/
}

const http = axios.create({
  baseURL: import.meta.env?.VITE_BASE_URL,
  headers: headers
})

http.interceptors.request.use((request) => {
  // Set authorization from the LocalStorage
  const userData = JSON.parse(localStorage.getItem('userData') || 'null')

  if (userData?.access_token && userData?.token_type) {
    const authorization: string =
      (userData.token_type || '').charAt(0).toUpperCase() +
      (userData.token_type || '').slice(1) +
      ' ' +
      userData.access_token
    request.headers.set('Authorization', authorization)
  }

  return request
})
http.interceptors.response.use(
  function (response) {
    // Any 2xx code
    return response
  },
  function (error: AxiosError<any>) {
    // Not 2xx code
    switch (error.response?.status) {
      case 401:
        // Handle Unauthorized calls here
        const userUserDataStore = useUserDataStore()
        const consultationListStore = useConsultationsStore()

        userUserDataStore?.clearUserData()
        consultationListStore?.clearConsultationList()

        router.push({ name: 'login' })

        toaster.warn(error.response?.data?.detail)
        break
    }
    return Promise.reject(error)
  }
)

export { http }
