import consultationDataService from '@/services/consultationDataService'
import { useUserDataStore } from '@/stores/user'
import { useConsultationsStore } from '@/stores/consultations'
import { WSEvent } from '@/types'
import { logError } from '@/utils/error-logger'
import { useWebSocket, useOnline } from '@vueuse/core'
import { computed, onBeforeUnmount, onMounted } from 'vue'
import { toaster } from '@/utils/toaster'
import { useI18n } from 'vue-i18n'

export default function useConsultationsWS() {
  const { t } = useI18n()
  const { userData } = useUserDataStore()
  const consultationsStore = useConsultationsStore()
  const online = useOnline()

  const wsURL = computed(() => `${import.meta.env?.VITE_WS_URL}${userData.user?._id}`)

  const { open, close, data, status } = useWebSocket(wsURL, {
    onMessage: async (_: WebSocket, event: MessageEvent<string>) => {
      const wsData: WSEvent = event.data ? JSON.parse(event.data) : null
      console.log('event: ', wsData, wsData?.event)

      switch (wsData?.event) {
        case 'deleted':
          consultationsStore.handleExcludeConsultation(wsData.consultationId)
          break

        case 'created':
          try {
            consultationsStore.aiProcessingConsultation = true
            consultationsStore.consolidatingConsultation = false

            await _updateConsultationDetailsInList(wsData.consultationId)
          } catch (error: any) {
            console.log(error)
            logError(
              "Error: user can't get consultation details, after WS ping it's created",
              'view/composables/useConsultations:onMessage()',
              error?.response?.data || error?.response || error
            )
          }
          break

        case 'ready':
          try {
            // consultationsStore.loadingConsultation = true
            consultationsStore.consolidatingConsultation = false
            consultationsStore.aiProcessingConsultation = false

            console.log('Ready but not updated')
            await _updateConsultationDetailsInList(wsData.consultationId)
            console.log('Ready and updated')
          } catch (error: any) {
            console.log(error)
            logError(
              "Error: user can't get consultation details, after WS ping it's ready",
              'view/composables/useConsultations:onMessage()',
              error?.response?.data || error?.response || error
            )
          } finally {
            consultationsStore.loadingConsultation = false
          }
          break

        default:
          break
      }
    },
    autoReconnect: {
      retries: 15,
      delay: 1000,
      onFailed: () => {
        consultationsStore.consolidatingConsultation = false
        consultationsStore.aiProcessingConsultation = false

        if (online.value) {
          toaster.error(t('consultationIndexView.waitingScreens.toaster.wsConnectionFailed'))
          logError(
            t('consultationIndexView.waitingScreens.toaster.wsConnectionFailed'),
            'view/composables/useConsultations:onFailed()',
            {}
          )
        }
      }
    }
  })

  const _updateConsultationDetailsInList = async (consultationId: string) => {
    try {
      const { data } = await consultationDataService.getConsultationById(consultationId)
      const isAlreadyExist = !!consultationsStore.consultationList.find(
        (item) => item._id === data._id
      )

      if (isAlreadyExist) {
        consultationsStore.handleUpdateConsultation(data)
      } else {
        consultationsStore.handleAddConsultation(data)
      }

      if (
        consultationsStore.selectedConsultationId &&
        !consultationsStore.isCreatingNewConsultation
      ) {
        consultationsStore.selectConsultation(consultationsStore.selectedConsultationId)
      }
    } catch (error: any) {
      console.log(error)
      logError(
        "Error: user can't get consultation details, after WS ping it's ready",
        'view/composables/useConsultations:onMessage()',
        error?.response?.data || error?.response || error
      )
    }
  }

  const _handleVisibilityChange = async () => {
    if (document.visibilityState === 'visible') {
      console.log('User unlocked the screen or switched back to the tab')

      consultationsStore.consultationList = await consultationsStore.getConsultationList({
        page: 1,
        size: (consultationsStore.pagination?.page || 1) * consultationsStore.pagination.size
      })

      if (
        consultationsStore.selectedConsultationId &&
        !consultationsStore.isCreatingNewConsultation
      ) {
        consultationsStore.selectConsultation(consultationsStore.selectedConsultationId)
      }

      if (status.value === 'CLOSED') {
        open()
      }
    } else if (document.visibilityState === 'hidden') {
      // Log events of going out of the app to correlate with potential audio loss
      if (!document.hasFocus()) {
        console.log('Tab is not active or minimized')
        /*logError(
          'Tab not active or minimized',
          'useConsultationWS',
          consultationsStore.recordingConsultationId
        )*/
      } else if (!navigator.onLine) {
        console.log('Screen might be locked')
        logError(
          'Screen might be locked',
          'useConsultationWS',
          consultationsStore.recordingConsultationId
        )
      } else {
        console.log('Browser window might be not visible')
        logError(
          'Browser window might be not visible',
          'useConsultationWS',
          consultationsStore.recordingConsultationId
        )
      }
    }
  }

  onMounted(() => {
    document.addEventListener('visibilitychange', _handleVisibilityChange)
    window.addEventListener('online', _handleVisibilityChange)
  })

  onBeforeUnmount(() => {
    document.removeEventListener('visibilitychange', _handleVisibilityChange)
    window.removeEventListener('online', _handleVisibilityChange)
  })

  return {
    wsURL,
    open,
    close,
    data,
    status
  }
}
