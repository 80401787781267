<template>
  <slot />
</template>

<script lang="ts" setup>
import { onErrorCaptured } from 'vue'
import { logError } from '@/utils/error-logger'

onErrorCaptured((err, instance, info) => {
  console.error(err, instance, info)
  // @ts-ignore
  const componentName = `The onErrorCaptured root hook catch error in: ${instance?.type?.name || 'Anonymous'}`

  logError(info, componentName, err)

  return false
})
</script>
