import { createRouter, createWebHashHistory } from 'vue-router'

import { loadLayoutMiddleware } from '@/routes/middleware/loadLayoutMiddleware'
import ConsultationsView from '@/views/consultation/Index.vue'
import { useUserDataStore } from '@/stores/user'

const router = createRouter({
  history: createWebHashHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: ConsultationsView,
      beforeEnter: [ensureLoggedIn],
      meta: {
        layout: 'DefaultLayout'
      }
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/LoginView.vue'),
      meta: {
        layout: 'DefaultLayout'
      },
      beforeEnter: [ensureLoggedOut]
    },
    {
      path: '/sign-up',
      name: 'sign-up',
      component: () => import('@/views/SignUpView.vue'),
      meta: {
        layout: 'DefaultLayout'
      },
      beforeEnter: [ensureLoggedOut]
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: () => import('@/views/ResetPasswordView.vue'),
      meta: {
        layout: 'DefaultLayout'
      },
      beforeEnter: [ensureLoggedOut]
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: () => import('@/views/ForgotPasswordView.vue'),
      meta: {
        layout: 'DefaultLayout'
      },
      beforeEnter: [ensureLoggedOut]
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'not-found',
      component: () => import('@/views/NotFound.vue'),
      meta: {
        layout: 'DefaultLayout'
      }
    }
  ]
})

router.beforeEach(() => {
  const { setUserDataFromLocalStorage } = useUserDataStore()

  setUserDataFromLocalStorage()

  return true
})

function ensureLoggedIn() {
  const { isLoggedIn } = useUserDataStore()

  if (!isLoggedIn) {
    router.push({ name: 'login' })
  }
}

function ensureLoggedOut() {
  const { isLoggedIn } = useUserDataStore()

  if (isLoggedIn) {
    router.push({ name: '' })
  }
}

// function hydrateProject({ params: { id: name } }: any) {
//   // here we could test if the given project exists in the database
//   // maybe we should load the list of projects from the database at some point

//   const store = useSettingsStore()
//   store.setProject(name)

//   return true
// }

router.beforeEach(loadLayoutMiddleware)

export default router
